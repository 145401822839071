import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`These are the built in components that you’ll have access to simply by using markdown. The whitespace around these components `}<strong parentName="p">{`is significant`}</strong>{`. If you encounter any errors, make sure you format the markdown and surounding space properly.`}</p>
      <p>{`For most pages, we recommend starting with a `}<inlineCode parentName="p">{`PageDescription`}</inlineCode>{` followed by `}<inlineCode parentName="p">{`AnchorLinks`}</inlineCode>{` if the content is long enough.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Text decoration</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Links</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Images</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Code blocks</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Blockquotes</AnchorLink>
    </AnchorLinks>
    <h2>{`Text decoration`}</h2>
    <p>{`Emphasis, aka italics, with `}<em parentName="p">{`asterisks`}</em>{` or `}<em parentName="p">{`underscores`}</em>{`.
Strong emphasis, aka bold, with `}<strong parentName="p">{`asterisks`}</strong>{` or `}<strong parentName="p">{`underscores`}</strong>{`.
Combined emphasis with `}<strong parentName="p">{`asterisks and `}<em parentName="strong">{`underscores`}</em></strong>{`.
Strikethrough uses two tildes. `}<del parentName="p">{`Scratch this.`}</del></p>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown": true
      }}>{`Emphasis, aka italics, with _asterisks_ or _underscores_.
Strong emphasis, aka bold, with **asterisks** or **underscores**.
Combined emphasis with **asterisks and _underscores_**.
Strikethrough uses two tildes. ~~Scratch this.~~
`}</code></pre>
    <h2>{`Headers`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` It’s generally considered `}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/semanticstructure/#contentstructure"
      }}>{`best practice`}</a>{` to include just one `}<inlineCode parentName="p">{`h1`}</inlineCode>{` heading per page. This heading signals the title or primary subject matter of the content to your audience. Because the title component generated at the top of each page already uses an `}<inlineCode parentName="p">{`h1`}</inlineCode>{` heading, we recommend using `}<inlineCode parentName="p">{`h2`}</inlineCode>{` tags for section headings within your content.`}</p>
    <h2>{`H2`}</h2>
    <h3>{`H3`}</h3>
    <h4>{`H4`}</h4>
    <h5>{`H5`}</h5>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`## H2

### H3

#### H4

##### H5
`}</code></pre>
    <h2>{`Lists`}</h2>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}<ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol></li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}<ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul></li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`1. First ordered list item
1. Item with a nested item
   1. Nested list item
1. In markdown, the actual numbers don't matter, just that it's a number

- Unordered list can use asterisks

* Or hyphens to create list items
`}</code></pre>
    <h2>{`Links`}</h2>
    <p><a parentName="p" {...{
        "href": "/components/demo"
      }}>{`I’m a local link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com"
      }}>{`I’m a markdown link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com",
        "title": "Carbon's Homepage"
      }}>{`I’m a markdown link with title`}</a></p>
    <p>{`URLs and URLs in angle brackets will automatically get turned into links.
`}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` and sometimes
example.com (but not on Github, for example).`}</p>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`[I'm a markdown link](https://www.carbondesignsystem.com)

[I'm a markdown link with title](https://www.carbondesignsystem.com "Google's Homepage")

[I'm a local link](/components/demo)

URLs and URLs in angle brackets will automatically get turned into links.
http://www.example.com or <http://www.example.com> and sometimes
example.com (but not on Github, for example).

Some text to show that the reference links can follow later.
`}</code></pre>
    <h2>{`Images`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACOElEQVQoz22T60/UQBTFO512ZvrutvsoZR8sW1xkQSJqSMgiyS4KigmCEFddYtjIBxNC4v//4Xg7BQyJH046nXvPL+dOO4ZpmijFGCNVT845bKFgOx6EH0OGdcgggXAD2NKBZUvycN37z1vJeARqVVBuCdjKgyKYiltQjQ5Us6fXkqCWICC3nwR5WD8BVpscpiXBpA/DMP4vqusexu+nqrylNJCXLw+xaRSDWWisbmBx8xt3d3+wWNzg+voXbm/v8JPWLaoZpn3fWwE5fwRaOn5ZZFxUyaIc6fYBJifn+DG/xpfLGc4vZvg6u8L43SfUXr0Hi5fABPWWHvLqIyAZhqVgChfMdsCcWDeanU3IrQka3QIr/QEs+hDKC9Ht9hFnXZijKcxlSllCSw95TUF+W1Fi4VGqECaJ+XWYaQdWewSxPkay3Eeet5E0MjSzHFm2hLjZBl/b0z0sWSZPqr2mDFCyDFYuZEQbkS6yWg7WHEB0txA2ckRRjKiWIk7qiMIYftKiCV6AtQo6mgzMSyuvZoR0hiVd1cCpYMUZBP0icqlA2h9hMNzE6mCILO+iRRo828LK8CX89jpkRkdBCXnQAHcTYsQVUDo+3KCGuE4jdQoUox283j/Czu4BDo9Pcfl9gc+Xc5xezDG7usHJ2Te8GR9iZ2+C3tom6lkPIaUuGdIJYAi6EUq5cFwPnh8ioLGStIlesYHd8VRD306OcTD9gKOTM+xPP6J4vo203tK9rhfAoRtVMqRU+AviuhFTjr4EjAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Alt text goes here",
        "title": "Alt text goes here",
        "src": "/static/977c28d3f95f7bbe8eb6faeb4788dc27/3cbba/quantum.png",
        "srcSet": ["/static/977c28d3f95f7bbe8eb6faeb4788dc27/7fc1e/quantum.png 288w", "/static/977c28d3f95f7bbe8eb6faeb4788dc27/a5df1/quantum.png 576w", "/static/977c28d3f95f7bbe8eb6faeb4788dc27/3cbba/quantum.png 1152w", "/static/977c28d3f95f7bbe8eb6faeb4788dc27/d9f40/quantum.png 1214w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`![Alt text goes here](images/quantum.png)
`}</code></pre>
    <h2>{`Code blocks`}</h2>
    <p>{`You can read in depth about syntax highlighting and advanced code snippet features on the `}<a parentName="p" {...{
        "href": "/components/code-blocks"
      }}>{`Code blocks`}</a>{` page.`}</p>
    <p>{`Inline `}<inlineCode parentName="p">{`code`}</inlineCode>{` has `}<inlineCode parentName="p">{`back-ticks around`}</inlineCode>{` it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "Title",
        "Title": true
      }}>{`Blocks of code have three back-ticks above and below.

You can specify a language by placing it after the backticks.

You can also supply a source code URL or title to go at the top of the code block
`}</code></pre>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`Inline \`code\` has \`back-ticks around\` it.

\`\`\`markdown Title
Blocks have three back-ticks above and below.
Pretend the backslashes aren't there.
\`\`\`
`}</code></pre>
    <p>{`You can view a list of included languages at the `}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js"
      }}>{`react-prism-renderer repo`}</a>{`.`}</p>
    <h2>{`Tables`}</h2>
    <p>{`Colons can be used to align columns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There must be at least 3 dashes separating each header cell.
The outer pipes (|) are optional, and you don’t need to make the
raw Markdown line up prettily. You can also use inline Markdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Less`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pretty`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Still`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`nicely`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`Colons can be used to align columns.

| Tables        |      Are      |   Cool |
| ------------- | :-----------: | -----: |
| col 3 is      | right-aligned | \$1600 |
| col 2 is      |   centered    |   \$12 |
| zebra stripes |   are neat    |    \$1 |

There must be at least 3 dashes separating each header cell.
The outer pipes (|) are optional, and you don't need to make the
raw Markdown line up prettily. You can also use inline Markdown.

| Markdown | Less      | Pretty     |
| -------- | --------- | ---------- |
| _Still_  | \`renders\` | **nicely** |
| 1        | 2         | 3          |
`}</code></pre>
    <h2>{`Blockquotes and citations`}</h2>
    <p>{`It is more important than ever that we own our own ethos, make palpable our brand values, and incorporate an instantly identifiable IBMness in everything we do.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a Blockquote.
This line is part of the same quote.`}</p>
    </blockquote>
    <blockquote>
      <cite>– Alison</cite>
    </blockquote>
    <p>{`It is more important than ever that we own our own ethos, make palpable our brand values, and incorporate an instantly identifiable IBMness in everything we do.`}</p>
    <h4>{`Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "path=components/markdown src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown",
        "path": "components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`> This is a Blockquote.
> This line is part of the same quote.

> <cite>– Alison</cite>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      